import React from 'react';
import {useLocation} from 'react-router-dom';

import {useAuth} from 'shared/auth/hooks/useAuth';
import {isStateVaild} from '../utils/is-state-vaild';

import {Loading} from 'shared/components/loading/Loading/Loading';

export const LoginPage = () => {
    const {login} = useAuth();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let from: any;
    if (isStateVaild(location.state)) {
        const state = location.state;
        from = state.from;
    }

    React.useEffect(() => {
        login({from});
    });

    return (
        <Loading/>
    );
};
