import axios from 'axios';

import {
    IBillingFrontendConfig,
    IBillingSubscription,
    ICheckoutSessionCreate,
    IPortalSession,
    IPortalSessionCreate,
} from '../models';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';
import {getAccessTokenSilently} from 'shared/utils/token';

export const getURLForBillingService = (path: string): string => {
    return `${Config.billingServiceURL}${path}`;
};

export const readBillingFrontendConfig = async (): Promise<IBillingFrontendConfig> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForBillingService('/frontend_config'), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readSubscription = async (userID: string): Promise<IBillingSubscription> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForBillingService(`/users/${userID}/subscription`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createPortalSession = async ({userID, returnURL}: IPortalSessionCreate): Promise<IPortalSession> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForBillingService(`/users/${userID}/portal_session`), {
        return_url: returnURL,
    }, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createCheckoutSession = async ({userID, returnURL}: ICheckoutSessionCreate) => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForBillingService(`/users/${userID}/checkout_session`), 
        {
            return_url: returnURL,
        },
        {
            headers: getAuthHeaders(accessToken),
        },
    );
    return response.data;
};
