import React from 'react';

import {useAuth} from 'shared/auth/hooks/useAuth';

import {ChatWebSocketProviderAuthenticated} from 'modules/chat-websocket/context/ChatWebsocketProviderAuthenticated';
import {
    ChatWebSocketProviderNotAuthenticated,
} from 'modules/chat-websocket/context/ChatWebsocketProviderNotAuthenticated';

interface IChatWebSocketProviderProps {
    children?: React.ReactNode;
}

export const ChatWebSocketProvider = ({children}: IChatWebSocketProviderProps) => {
    const [wsAccessToken, setWsAccessToken] = React.useState<string | undefined>(undefined);
    const {isAuthenticated, getAccessTokenSilently} = useAuth();

    React.useEffect(() => {
        if (!isAuthenticated) {
            setWsAccessToken(undefined);
        } else {
            (async () => {
                const accessToken = await getAccessTokenSilently();
                setWsAccessToken(accessToken);
            })();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    // Provide a different chat websocket context depending on whether the user is authenticated or not. We need to do
    // this because we need an access token to connect to the websocket endpoint.
    return (
        <>
            {wsAccessToken ? (
                <ChatWebSocketProviderAuthenticated wsAccessToken={wsAccessToken}>
                    {children}
                </ChatWebSocketProviderAuthenticated>
            ) : (
                <ChatWebSocketProviderNotAuthenticated>
                    {children}
                </ChatWebSocketProviderNotAuthenticated>
            )}
        </>
    );
};
