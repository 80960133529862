import React, {useRef, useState} from 'react';

import {createSearchParams, useNavigate} from 'react-router-dom';
import {Button, Card} from 'react-bootstrap';

import {IProfile, Role} from 'modules/profile/models/IProfile';

import {ProfileCardPicture} from 'shared/components/ProfileCard/ProfileCardPicture/ProfileCardPicture';
import {ProfileCardDetails} from 'shared/components/ProfileCard/ProfileCardDetails';
import {MediaCard} from 'shared/components/MediaCard/MediaCard';
import {MediaCardTags} from 'shared/components/MediaCard/MediaCardTags/MediaCardTags';
import {MediaCardFooter} from 'shared/components/MediaCard/MediaCardFooter/MediaCardFooter';

import './style.scss';
import {DropdownCaret} from '../DropdownCaret/DropdownCaret';
import {CalendarIcon} from 'shared/icons/dashboard';
import {IconChat} from 'shared/icons/common';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {createChat} from 'modules/chat/api';
import {ItemKind} from 'modules/item-key';
import {toastAxiosError} from 'shared/utils/error';
import {chatInsertSelector, chatListBumpToTopSelector} from 'modules/chat/state';
import {useSetRecoilState} from 'recoil';
import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {Modal} from 'react-bootstrap';
import {getFullName} from 'modules/profile/utils';
import {Loading} from '../loading/Loading/Loading';
import {NotAuthenticatedModalBody} from '../modals/NotAuthenticatedModalBody/NotAuthenticatedModalBody';

interface IProfileCardProps {
    profile: IProfile;
    className?: string;
    onAvailabilityClick: () => void;
    currentRole?: Role;
    isFromNetwork?: boolean;
}

export const ProfileCard = ({
    currentRole,
    profile,
    className,
    onAvailabilityClick,
    isFromNetwork = false,
}: IProfileCardProps) => {
    const profileUrl = `/profiles/${profile.id}`;
    const navigate = useNavigate();
    const {isAuthenticated, tokenData} = useAuth();
    const [isConnectExpanded, setIsConnectExpanded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isChatUnauthenticatedModal, setIsChatUnauthenticatedModal] = useState<boolean>(false);
    const connectBtnContainerRef = useRef(null);

    const insertChat = useSetRecoilState(chatInsertSelector);
    const bumpChatToTop = useSetRecoilState(chatListBumpToTopSelector);

    useOutsideClick(connectBtnContainerRef, () => setIsConnectExpanded(false));

    const handleChatClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isAuthenticated || !tokenData) return setIsChatUnauthenticatedModal(true);

        if (isSubmitting || profile.id === tokenData.id) return;

        setIsSubmitting(true);
        (async () => {
            try {
                const newChat = await createChat({
                    user_ids: [profile.id, tokenData.id],
                    is_active: true,
                    reference: {
                        kind: ItemKind.CHAT,
                        id: '0',
                    },
                });

                const options = {
                    pathname: '/dashboard/chat',
                    search: `?${createSearchParams({chat_id: newChat.id})}`,
                };

                insertChat(newChat);
                bumpChatToTop(newChat.id);
                navigate(options, {replace: true});
            } catch (e) {
                toastAxiosError(e, 'Create Chat');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <>
            <MediaCard className={className ? className : 'ProfileCard'}>
                <ProfileCardPicture profile={profile} to={profileUrl} />
                <Card.Body className="ProfileCard__card-body">
                    <ProfileCardDetails
                        currentRole={currentRole}
                        onClickDetail={() => {
                            // let senderElement = e.target;
                            navigate(profileUrl);
                            return null;
                        }}
                        profile={profile}
                    />
                    <MediaCardFooter variant="dashed">
                        <div className="ProfileCard__TagsBar">
                            <MediaCardTags tagIds={profile.tag_ids} />
                        </div>
                        <div
                            ref={connectBtnContainerRef}
                            className="ProfileCard__Checkbtn"
                            onClick={() => {
                                navigate(profileUrl);
                                return null;
                            }}
                        >
                            <button
                                className={`connectbtn ${isConnectExpanded && 'expanded-connectbtn'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsConnectExpanded(!isConnectExpanded);
                                }}
                                type="button"
                            >
                                CONNECT
                                <DropdownCaret className={`${isConnectExpanded && 'expanded-DropdownCaret'}`} />
                            </button>
                            <div
                                className={`connectbtnrow ${isConnectExpanded && 'expanded-connectbtnrow'} ${
                                    isFromNetwork && 'network-connectbtnrow'
                                } ${isConnectExpanded && isFromNetwork && 'expanded-network-connectbtnrow'}`}
                            >
                                <Button onClick={handleChatClick}>
                                    <IconChat />
                                    Message
                                </Button>
                                <Button
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onAvailabilityClick();
                                    }}
                                >
                                    <CalendarIcon />
                                    Book Diary
                                </Button>
                            </div>
                        </div>
                    </MediaCardFooter>
                </Card.Body>
            </MediaCard>

            <Modal
                show={isChatUnauthenticatedModal}
                centered
                size="lg"
                onHide={() => setIsChatUnauthenticatedModal(false)}
                animation={false}
            >
                <Modal.Header closeButton className="p-3">
                    {profile && (
                        <Modal.Title className="BookingModal__title">{getFullName(profile)}'s Chat</Modal.Title>
                    )}
                </Modal.Header>

                <React.Suspense
                    fallback={
                        <Modal.Body className="p-5">
                            <Loading />
                        </Modal.Body>
                    }
                >
                    {profile && !isAuthenticated && <NotAuthenticatedModalBody />}
                </React.Suspense>
            </Modal>
        </>
    );
};
