import {parseEnumValue} from 'shared/utils/string';

export enum DeploymentEnvironment {
    PRODUCTION = 'production',
    STAGING = 'staging',
}

const readConfigEnvMaybe = (key: string): string | undefined => {
    if (key in process.env && process.env[key]) {
        return process.env[key] as string;
    } else {
        return undefined;
    }
};

const readConfigEnv = (key: string): string => {
    const envValue = readConfigEnvMaybe(key);
    if (envValue) {
        return envValue;
    } else {
        throw new Error(`Missing environment variable ${key}`);
    }
};

const readConfigEnvEnum = <T extends Record<string, string>>(key: string, enumObj: T): T[keyof T] => {
    const envValue = readConfigEnvMaybe(key);
    if (!envValue) {
        throw new Error(`Missing environment variable ${key}`);
    }
    return parseEnumValue(enumObj, envValue);
};

export const Config = {
    env: readConfigEnvEnum('REACT_APP_ENV', DeploymentEnvironment),
    fileServiceURL: readConfigEnv('REACT_APP_FILE_SERVICE'),
    contactServiceURL: readConfigEnv('REACT_APP_CONTACT_SERVICE'),
    notificationServiceURL: readConfigEnv('REACT_APP_NOTIFICATION_SERVICE'),
    mainServiceURL: readConfigEnv('REACT_APP_MAIN_SERVICE'),
    documentServiceURL: readConfigEnv('REACT_APP_DOCUMENT_SERVICE'),
    chatServiceURL: readConfigEnv('REACT_APP_CHAT_SERVICE'),
    helpServiceURL: readConfigEnv('REACT_APP_HELP_SERVICE'),
    userServiceURL: readConfigEnv('REACT_APP_USER_SERVICE'),
    paymentServiceURL: readConfigEnv('REACT_APP_PAYMENT_SERVICE'),
    billingServiceURL: readConfigEnv('REACT_APP_BILLING_SERVICE'),
    signatureServiceURL: readConfigEnv('REACT_APP_SIGNATURE_SERVICE'),
    relationshipServiceURL: readConfigEnv('REACT_APP_RELATIONSHIP_SERVICE'),
    jobServiceURL: readConfigEnv('REACT_APP_JOB_SERVICE'),
    homepageCacheURL: readConfigEnv('REACT_APP_HOMEPAGE_CACHE_URL'),
    auth0Domain: readConfigEnv('REACT_APP_AUTH0_DOMAIN'),
    auth0ClientId: readConfigEnv('REACT_APP_AUTH0_CLIENT_ID'),
    auth0Audience: readConfigEnv('REACT_APP_AUTH0_AUDIENCE'),
    auth0CookieDomain: readConfigEnv('REACT_APP_AUTH0_COOKIE_DOMAIN'),
    auth0ClaimsNamespace: readConfigEnv('REACT_APP_AUTH0_CLAIMS_NAMESPACE'),
    socketConnection: readConfigEnv('REACT_APP_SOCKET_CONNECTION_URL'),
    apryseKey: readConfigEnv('REACT_APP_APRYSE_KEY'),
    recaptchaV2SiteKey: readConfigEnv('REACT_APP_RECAPTCHA_V2_SITE_KEY'),
    gleapApiKey: readConfigEnv('REACT_APP_GLEAP_API_KEY'),
};
