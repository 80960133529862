import React, {useState} from 'react';

import {useRecoilValue} from 'recoil';
import classNames from 'classnames';
import {Button, Modal} from 'react-bootstrap';

import {IFilterOption} from 'shared/models/filter-option/IFilterOption';
import {IProfilesPageFilters} from 'shared/state/profiles-page';
import {tagListReadSelector} from 'modules/tag/state/tag-list';
import {rateFilters, RateType} from 'shared/models/rate-filters/rate-filters';
import {professionFilters} from 'modules/profile/constants';

import {RangeSlider} from 'public/ProfileMarketplace/MarketplaceFilter/RangeSlider/RangeSlider';
import {BadgeSelectControl} from './BadgeSelectControl/BadgeSelectControl';
import {DropdownSelect} from 'shared/components/form/DropdownSelect/DropdownSelect';
import {ProfessionSlider} from 'public/ProfileMarketplace/MarketplaceFilter/ProfessionSlider/ProfessionSlider';

import './style.scss';

interface IMarketplaceFilterProps {
    showFilter: boolean;
    setShowFilter: (showFilter: boolean) => void;
    setIsNextLoading: (showFilter: boolean) => void;
    setIsOnlySkeleton: (showFilter: boolean) => void;
    setShowSkeletons: (showFilter: boolean) => void;
    locationOptions: IFilterOption<string | undefined>[];
    filters: IProfilesPageFilters;
    onChange: (filters: IProfilesPageFilters) => void;
}

export interface IPriceRange {
    min: number;
    max: number;

    [key: string]: number;
}

export const MarketplaceFilter = ({
    showFilter,
    setShowFilter,
    locationOptions,
    onChange,
    filters,
    setIsNextLoading,
    setIsOnlySkeleton,
    setShowSkeletons,
}: IMarketplaceFilterProps) => {
    // TODO: use tag list loadable
    const tags = useRecoilValue(tagListReadSelector);
    const [mobileFilter, setMobileFilter] = useState<any>({});
    // const [priceRange, setPriceRange] = useState<any>();
    // const debouncedSearchTerm: IPriceRange = useDebounce(priceRange, 500);

    // useEffect(() => {
    //     if (debouncedSearchTerm) {
    //         if (debouncedSearchTerm.min === 0 && debouncedSearchTerm.max === 10000) {
    //             setIsNextLoading(true);
    //             setIsOnlySkeleton(true);
    //             onChange({
    //                 page: 0,
    //                 limit: 30,
    //             });
    //         } else {
    //             setIsOnlySkeleton(true);
    //             setIsNextLoading(false);
    //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //             const filteredObj: any = {
    //                 ...filters,
    //                 priceRange: debouncedSearchTerm,
    //             };
    //             delete filteredObj.limit;
    //             onChange(filteredObj);
    //             // onChange({...filters, limit: 100, priceRange: debouncedSearchTerm});
    //         }
    //     }
    // }, [debouncedSearchTerm]);

    const tagOptions = React.useMemo<IFilterOption<string | undefined>[]>(
        () => [
            {
                title: 'All',
                value: undefined,
            },
            ...tags.map((tag) => ({
                title: tag.title,
                value: tag.id,
            })),
        ],
        [tags],
    );

    const handleFilter = () => {
        onChange({...filters, ...mobileFilter});
        setShowFilter(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removeLimit = (filterObj: any) => {
        const tmpFilterObj = {...filterObj};
        delete tmpFilterObj.limit;
        return tmpFilterObj;
    };

    return (
        <div className="MarketplaceFilter">
            <div className="MarketplaceFilter__badgefilter">
                <ProfessionSlider
                    value={filters.profession}
                    onChange={profession => {
                        const filtered = removeLimit(filters);
                        onChange({
                            ...filtered,
                            profession,
                        });
                    }}
                />
            </div>
            <div
                className={classNames('MarketplaceFilter__container flex_align_row_center justify-content-around', {
                    'MarketplaceFilter__container--show': showFilter,
                })}
            >
                <div className="filterClose" onClick={() => setShowFilter(false)}>
                    <div className="col-9">FILTER</div>
                    <div className="col-3 d-flex justify-content-end closeIcon">
                        <img src="/assets/close.svg" alt="close" />
                    </div>
                </div>
                <div className="d-flex justify-content-center flex-column">
                    <div className="MarketplaceFilter__label mr-0 mb-1 ml-1">Location</div>
                    <DropdownSelect
                        id="locations"
                        onChange={(value) => {
                            const filtered = removeLimit(filters);
                            onChange({...filtered, location: value});
                        }}
                        options={locationOptions}
                        value={filters.location}
                        variant="pill"
                    />
                </div>
                <div className="flex_align_row_center rangeslider margin_right_24 flex-column sliderMargin ml-2">
                    <div className="MarketplaceFilter__label align-self-baseline mb-1">Price Range</div>
                    <RangeSlider
                        widthSlider="180px"
                        // onChange={(value) => setPriceRange(value)}
                        onChange={(value) => {
                            const filtered = removeLimit(filters);
                            onChange({...filtered, priceRange: value});
                            // setPriceRange(value);
                        }}
                        // value={priceRange ?? {min: 0, max: 10000}}
                        value={filters.priceRange ?? {min: 0, max: 10000}}
                    />
                </div>
                <div className="d-flex justify-content-center flex-column">
                    <div className="MarketplaceFilter__label mr-0 mb-1 ml-1">Sort By Rate</div>
                    <DropdownSelect
                        id="sortByRate"
                        onChange={(value) => {
                            const filtered = removeLimit(filters);
                            onChange({...filtered, rateType: value as RateType});
                            // onChange({...filters, limit: 100, rateType: value as RateType});
                        }}
                        options={rateFilters}
                        value={filters.rateType}
                        variant="pill"
                    />
                </div>
                <div className="d-flex justify-content-center flex-column">
                    <div className="MarketplaceFilter__label mr-0 mb-1 ml-1">Speciality</div>
                    <DropdownSelect
                        id="speciality"
                        onChange={(value) => {
                            const filtered = removeLimit(filters);
                            onChange({...filtered, tagId: value});
                        }}
                        options={tagOptions}
                        value={filters.tagId}
                        variant="pill"
                    />
                </div>
                <div className="row mainFilter" style={{marginLeft: '20px', marginRight: '20px'}}>
                    <div className="col-6 p-0 btnFilter">
                        <div className="resetBtn" onClick={() => setShowFilter(false)}>
                            RESET
                        </div>
                    </div>
                    <div className="col-6 p-0 btnFilter">
                        {' '}
                        <div className="applyBtn" onClick={() => setShowFilter(false)}>
                            APPLY
                        </div>
                    </div>
                </div>

                {/* Mobile Filter Modal */}
                <Modal show={showFilter} centered onHide={() => setShowFilter(false)}>
                    <Modal.Header closeButton className="p-3">
                        <Modal.Title className="BookingModal__title">Filters</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="p-4">
                        <BadgeSelectControl
                            options={professionFilters}
                            name="profession"
                            value={mobileFilter.profession}
                            onChange={(value) => {
                                const filtered = removeLimit(mobileFilter);
                                setMobileFilter({
                                    ...filtered,
                                    profession: value,
                                });
                            }}
                        />

                        <div className="FilterMobileGutterSpace d-flex justify-content-center flex-column">
                            <div className="MarketplaceFilter__label mr-0 mb-1 ml-1">Location</div>
                            <DropdownSelect
                                id="locations"
                                onChange={(value) => {
                                    const filtered = removeLimit(mobileFilter);
                                    setMobileFilter({
                                        ...filtered,
                                        location: value,
                                    });
                                }}
                                options={locationOptions}
                                value={mobileFilter.location}
                                variant="pill"
                            />
                        </div>

                        <div className="FilterMobileGutterSpace flex_align_row_center rangeslider margin_right_24 flex-column sliderMargin ml-2">
                            <div className="MarketplaceFilter__label align-self-baseline mb-1">Price Range</div>
                            <RangeSlider
                                widthSlider="97%"
                                onChange={(value) => {
                                    const filtered = removeLimit(mobileFilter);
                                    setMobileFilter({
                                        ...filtered,
                                        priceRange: value,
                                    });
                                }}
                                value={mobileFilter.priceRange ?? {min: 0, max: 10000}}
                            />
                        </div>

                        <div className="FilterMobileGutterSpace d-flex justify-content-center flex-column">
                            <div className="MarketplaceFilter__label mr-0 mb-1 ml-1">Sort By Rate</div>
                            <DropdownSelect
                                id="sortByRate"
                                onChange={(value) => {
                                    const filtered = removeLimit(mobileFilter);
                                    setMobileFilter({
                                        ...filtered,
                                        rateType: value as RateType,
                                    });
                                }}
                                // onChange={(value) => onChange({...filters, rateType: value as RateType})}
                                options={rateFilters}
                                value={mobileFilter.rateType}
                                variant="pill"
                            />
                        </div>

                        <div className="FilterMobileGutterSpace d-flex justify-content-center flex-column">
                            <div className="MarketplaceFilter__label mr-0 mb-1 ml-1">Speciality</div>
                            <DropdownSelect
                                id="speciality"
                                onChange={(value) => {
                                    const filtered = removeLimit(mobileFilter);
                                    setMobileFilter({
                                        ...filtered,
                                        tagId: value,
                                    });
                                }}
                                options={tagOptions}
                                value={mobileFilter.tagId}
                                variant="pill"
                            />
                        </div>
                        <div className="d-flex w-100 justify-content-center mt-2">
                            <Button
                                type="submit"
                                variant="featured"
                                onClick={() => handleFilter()}
                                className="btn-md px-5"
                            >
                                Search
                            </Button>
                            {/* <Button
                                type="submit"
                                variant="featured"
                                onClick={() => {
                                    setMobileFilter({});
                                    onChange({page: 0, limit: 200});
                                }}
                                className="btn-md px-5"
                            >
                                reset
                            </Button> */}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};
