import React from 'react';

import {Button} from 'react-bootstrap';
import {toast} from 'shared/utils/toast';

import {ChooseProfilePictureModal} from 'shared/components/UploadProfilePicture/ChooseProfilePictureModal';
import {UploadProfilePictureModal} from 'shared/components/UploadProfilePicture/UploadProfilePictureModal';

import './style.scss';

interface IUploadProfilePictureProps {
    userId: string;
    disabled?: boolean;
}

interface IFileNameAndData {
    name: string;
    data: string;
}

export const UploadProfilePicture = ({userId, disabled}: IUploadProfilePictureProps) => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [showUploadModal, setShowUploadModal] = React.useState<boolean>(false);
    const [showChooseModal, setShowChooseModal] = React.useState<boolean>(false);
    const [fileData, setFileData] = React.useState<IFileNameAndData>();

    const onFileCHanged = () => {
        const file = fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    const image: any = new Image();
                    image.src = e.target.result;
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;

                        if (width > 520 && height > 380) {
                            const fileName = file.name;
                            const fileData = image.src;
                            setFileData({name: fileName, data: fileData as string});
                            setShowUploadModal(true);
                            // clear the file input so that onChange is triggered even if same file is chosen again
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                        } else {
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            toast.error(
                                'Upload failed',
                                'Your uploaded image does not meet our platform\'s image specifications.',
                            );
                        }
                    };
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const onUploadClick = () => {
        if (fileInputRef.current && fileInputRef.current.click) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <div>
                <input
                    ref={fileInputRef}
                    type="file"
                    id="upload"
                    accept="image/*"
                    className="UploadProfilePicture__input"
                    onChange={onFileCHanged}
                    style={{display: 'none'}}
                    disabled={disabled}
                />
                <div className="Uploadbtn mt-3">
                    <Button variant="link" onClick={onUploadClick} className="p-0" disabled={disabled}>
                        Upload
                    </Button>
                    <Button
                        variant="link"
                        onClick={() => setShowChooseModal(true)}
                        className="p-0 ml-4"
                        disabled={disabled}
                    >
                        Use Existing
                    </Button>
                </div>
            </div>
            {showUploadModal && fileData && (
                <UploadProfilePictureModal
                    show={showUploadModal}
                    onClose={() => setShowUploadModal(false)}
                    fileName={fileData.name}
                    fileData={fileData.data}
                    userId={userId}
                />
            )}
            {showChooseModal && (
                <ChooseProfilePictureModal
                    userId={userId}
                    show={showChooseModal}
                    onClose={() => setShowChooseModal(false)}
                />
            )}
        </>
    );
};
