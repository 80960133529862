import React from 'react';

import {Auth0Provider} from '@auth0/auth0-react';

import {Config} from 'config';
import {useNavigate} from 'react-router-dom';
import {authRedirectLocalStorageKey} from 'shared/auth/models';

import {AuthProviderContent} from 'shared/auth/components/AuthProvider/AuthProviderContent';

interface IAuthProviderProps {
    children?: React.ReactNode;
}

export function AuthProvider({children}: IAuthProviderProps) {
    const navigate = useNavigate();
    return (
        <Auth0Provider
            domain={Config.auth0Domain}
            clientId={Config.auth0ClientId}
            redirectUri={window.location.origin}
            audience={Config.auth0Audience}
            cookieDomain={Config.auth0CookieDomain}
            onRedirectCallback={() => {
                // read url from local storage, if there's a value redirect to it
                const url = localStorage.getItem(authRedirectLocalStorageKey);
                localStorage.removeItem(authRedirectLocalStorageKey);
                if (url) {
                    navigate(url);
                } else {
                    navigate(window.location.pathname);
                }
            }}
        >
            <AuthProviderContent>
                {children}
            </AuthProviderContent>
        </Auth0Provider>
    );
}
