import React from 'react';

import classNames from 'classnames';

import {BadgeFilter} from 'shared/components/BadgeFilter/BadgeFilter';
import {IDropdownSelectOption} from 'shared/components/form/DropdownSelect/DropdownSelect';

import './style.scss';

interface IBadgeSelectControlProps<ValueType> {
    name: string;
    value?: ValueType;
    onChange: (value: ValueType) => void;
    options: IDropdownSelectOption[];
    className?: string;
}

export const BadgeSelectControl = <ValueType extends unknown>({
    value,
    name,
    onChange,
    options,
    className,
}: IBadgeSelectControlProps<ValueType>) => {

    return (
        <div className={classNames('BadgeSelectControl', className)}>
            {options.map(option => (
                <BadgeFilter
                    key={option.value || ''}
                    onClick={() => onChange(option.value)}
                    title={option.title}
                    active={value === option.value}
                />
            ))}
        </div>
    );
};
