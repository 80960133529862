import React from 'react';

import {useNavbar} from 'shared/hooks/useNavbar';

import {PlainContentPage} from 'shared/components/PlainContentPage/PlainContentPage';
import {PlainContentPageSection} from 'shared/components/PlainContentPage/PlainContentPageSection';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const PrivacyPage = () => {
    useNavbar(true);

    return (
        <PlainContentPage title="Privacy Policy" className="pb-5">
            <PageHelmet
                title="Privacy Policy"
                description="Our Privacy Policy explains what personal information we collect, how we use personal information, how personal information is shared, and privacy rights."
            />
            <PlainContentPageSection title="Introduction">
                <ol start={1} className="PrivacyPage__ol pt-4">
                    <li>
                        In this Privacy Policy, ‘us’, ‘we’ or ‘our’ means NewLaw Chambers Pty Ltd ACN 642 771 239. We
                        are committed to respecting your privacy. Our Privacy Policy sets out how we collect, use, store
                        and disclose your personal information.
                    </li>
                    <li>
                        By providing personal information to us, you consent to our collection, use and disclosure of
                        your personal information in accordance with this Privacy Policy and any other arrangements that
                        apply between us. We may change our Privacy Policy from time to time by publishing changes to it
                        on our platform. We encourage you to check our platform periodically to ensure that you are
                        aware of our current Privacy Policy.
                    </li>
                    <li>
                        We are bound by the Australian Privacy Principles (APPs) contained in the Privacy Act 1988
                        (Privacy Act 1988).
                    </li>
                    <li>
                        Personal information is defined in the Privacy Act to mean any information or an opinion about
                        an identified individual, or an individual who is reasonably identifiable, whether the
                        information or opinion is true or not, and whether or not the information or opinion is recorded
                        in a material form. For example, this may include your name, age, gender, postcode and contact
                        details. It may also include financial information, including your credit card information.
                    </li>
                    <li>
                        By providing personal information to us, you consent to our storage, maintenance, use and
                        disclosure of your personal information in accordance with this Privacy Policy.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Types of personal information we collect">
                <ol start={6} className="PrivacyPage__ol pt-4 pb-2">
                    <li>
                        The personal information we collect about you may include the following:
                        <ul>
                            <li>
                                name;
                            </li>
                            <li>
                                mailing or street address;
                            </li>
                            <li>
                                email address;
                            </li>
                            <li>
                                social media information;
                            </li>
                            <li>
                                telephone number and other contact details;
                            </li>
                            <li>
                                age;
                            </li>
                            <li>
                                date of birth;
                            </li>
                            <li>
                                credit card information;
                            </li>
                            <li>
                                information about your legal requirements;
                            </li>
                            <li>
                                information about your business or personal circumstances;
                            </li>
                            <li>
                                information in connection with client surveys, questionnaires and promotions;
                            </li>
                            <li>
                                your device identity and type;
                            </li>
                            <li>
                                IP address;
                            </li>
                            <li>
                                geo-location information;
                            </li>
                            <li>
                                page view statistics, advertising data and standard web log information; and
                            </li>
                            <li>
                                any other information provided by you to us via our online platform or our online
                                presence, or otherwise required by us or provided by you.
                            </li>
                        </ul>
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="How we collect personal information">
                <ol start={7} className="PrivacyPage__ol pt-4 pb-2">
                    <li>
                        We may collect personal information either directly from you, or from third parties, including
                        where you:
                        <ul>
                            <li>
                                contact us through on our platform; communicate with us via email, telephone, SMS,
                                social applications (such as LinkedIn, Facebook, Twitter or Linkedin) or otherwise;
                            </li>
                            <li>
                                interact with our platform;
                            </li>
                            <li>
                                social applications, services, content and advertising; and invest in our business or
                                enquire as to a potential purchase in our business.
                            </li>
                        </ul>
                    </li>
                    <li>
                        We may also collect personal information from you when you use or access our platform or our
                        social media pages. This may be done through use of web analytics tools (including Google
                        Analytics), ‘cookies’ or other similar tracking technologies that allow us to track and analyse
                        your website usage. Cookies are small files that store information on your computer, mobile
                        phone or other device and enable and allow the creator of the cookie to identify when you visit
                        different websites. If you do not wish information to be stored as a cookie, you can disable
                        cookies in your web browser.
                    </li>
                    <li>
                        In addition, when you apply for a job or position with us we may collect certain information
                        from you (including your name, contact details, working history and relevant records checks)
                        from any recruitment consultant, your previous employers and others who may be able to provide
                        information to us to assist in our decision on whether or not to make you an offer of employment
                        or engage you under a contract.
                    </li>
                    <li>
                        This Privacy Policy does not apply to acts and practices in relation to employee records of
                        current and former employees which are exempt from the Privacy Act.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Purposes">
                <ol start={11} className="PrivacyPage__ol pt-4 pb-2">
                    <li>
                        We collect, hold, use and disclose your personal information for the following purposes:
                        <ul>
                            <li>
                                to respond to your enquiries;
                            </li>
                            <li>
                                to provide services or information to you;
                            </li>
                            <li>
                                for record keeping and administrative purposes; to provide information about you to our
                                contractors, employees, consultants, agents or other third parties for the purpose of
                                providing services to you;
                            </li>
                            <li>
                                to improve and optimise our service offering and customer experience;
                            </li>
                            <li>
                                to comply with our legal obligations, resolve disputes or enforce our agreements with
                                third parties;
                            </li>
                            <li>
                                to send you marketing and promotional messages and other information that may be of
                                interest to you and for the purpose of direct marketing (in accordance with the Spam
                                Act). In this regard, we may use email, SMS, social media or mail to send you direct
                                marketing communications. You can opt out of receiving marketing materials from us by
                                using the opt-out facility provided (an unsubscribe link);
                            </li>
                            <li>
                                to send you administrative messages, reminders, notices, updates, security alerts, and
                                other information requested by you;
                            </li>
                            <li>
                                and to consider an application of employment from you.
                            </li>
                        </ul>
                    </li>
                    <li>
                        We may disclose your personal information to cloud-providers, contractors and other third
                        parties located outside Australia. If we do so, we will take reasonable steps to ensure that any
                        overseas recipient deals with such personal information in a manner consistent with the APPs.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Security">
                <ol start={13} className="PrivacyPage__ol pb-2 pt-4">
                    <li>
                        Rightful takes platform security very seriously. We take reasonable steps to protect information
                        on the platform (including personal information) from misuse, interference and loss, as well as
                        unauthorised access, modification or disclosure by a number of physical, administrative,
                        personnel and technical protective measures. However, no method of electronic storage is 100%
                        secure. We cannot guarantee the absolute security of information on our platform.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Links">
                <ol start={14} className="PrivacyPage__ol pb-2 pt-4">
                    <li>
                        Our website may contain links to other websites. Those links are provided for convenience and
                        may not remain current or be maintained. We are not responsible for the privacy practices of
                        those linked websites and we suggest you review the privacy policies of those websites before
                        using them.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Requesting access or correcting your personal information">
                <ol start={15} className="PrivacyPage__ol pb-2 pt-4">
                    <li>
                        If you wish to request access to the personal information we hold about you, please contact us
                        using the contact details set out below including your name and contact details. We may need to
                        verify your identity before providing you with your personal information. In some cases, we may
                        be unable to provide you with access to all your personal information and where this occurs, we
                        will explain why. We will deal with all requests for access to personal information within a
                        reasonable timeframe. If you think that any personal information we hold about you is
                        inaccurate, please contact us using the contact details set out below and we will take
                        reasonable steps to ensure that it is corrected.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Complaints">
                <ol start={16} className="PrivacyPage__ol pb-2 pt-4">
                    <li>
                        If you wish to complain about how we handle your personal information, please contact us using
                        the details set out below including your name and contact details. We will investigate your
                        complaint promptly and respond to you within a reasonable time and in accordance with our legal
                        obligations.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Contact us">
                <ol start={17} className="PrivacyPage__ol pb-2 pt-4 margin-bottom-6">
                    <li>
                        For further information about our Privacy Policy or practices please contact us using the
                        details set out below:
                        <br/>
                        Email: enquiries@righful.com.au
                    </li>
                </ol>
            </PlainContentPageSection>
            <br/>
            Our privacy policy was last updated on 30 September 2022.
        </PlainContentPage>
    );
};
