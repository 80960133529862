import React from 'react';

import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {InteractiveLogo} from 'shared/components/InteractiveLogo/InteractiveLogo';

import './style.scss';

interface IHomepageHeaderProps {
    onLoad: () => void;
}

export const HomePageHeader = ({onLoad}: IHomepageHeaderProps) => {
    return (
        <div className="HomePage__section HomePageHeader">
            <InteractiveLogo onLoad={onLoad} />
            <h2 className="HomePageHeader__title">
                Australia's leading
                <br />
                legal talent
            </h2>
            <div className="d-flex justify-content-center">
                <p className="HomePageHeader__textdes">
                    Easily source legal talent, build a legal project, manage payments and so much more
                    <br />
                </p>
            </div>
            <div className="homepage--btn">
                <Button as={Link} variant="featured" className="HomePageHeader__button" to="/network">
                    FIND MY LEGAL TEAM
                </Button>
                <Button as={Link} variant="outline-primary" className="HomePageHeader__button" to="/become-a-member">
                    BOOK A DEMO
                </Button>
            </div>
        </div>
    );
};
