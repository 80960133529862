import React from 'react';

import {Link} from 'react-router-dom';

import {IReview} from 'modules/review/models';
import {useBool} from 'shared/hooks/useBool';

import {Editpen} from 'shared/icons/Homepage';
import {UpdateReviewModal} from 'shared/components/review/UpdateReviewModal/UpdateReviewModal';
import {DeleteReviewModal} from 'shared/components/review/DeleteReviewModal/DeleteReviewModal';

import './style.scss';

interface IReviewCardControlsProps {
    reviewId: string;
    authorId?: string;
    userId: string;
    reviewDetails: IReview;
}

export const ReviewCardControls = ({reviewId, reviewDetails}: IReviewCardControlsProps) => {
    const [showDeleteModal, setShowDeleteModal] = useBool();
    const [showUpdateModal, setShowUpdateModal] = useBool();

    const onUpdateClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowUpdateModal(true);
    };

    const onDeleteClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowDeleteModal(true);
    };

    return (
        <>
            <div className="ReviewCardControls">
                <Link to="#" onClick={onUpdateClick}>
                    <Editpen className="ReviewCardControls__icon"/>
                </Link>
                <Link to="#" onClick={onDeleteClick}>
                    <img
                        src="/assets/SVG/bin.svg"
                        alt="bin"
                        className="ReviewCardControls__icon"
                    />
                </Link>
            </div>
            <UpdateReviewModal
                show={showUpdateModal}
                review={reviewDetails}
                onHide={() => setShowUpdateModal(false)}
            />
            <DeleteReviewModal
                reviewId={reviewId}
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
            />
        </>
    );
};
