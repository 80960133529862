import React from 'react';

import {Col, Container, Form, Row} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {Link} from 'react-router-dom';

import {Role} from 'modules/profile/models/IProfile';
import {IProfileCreateForm} from 'auth/SignupPage/SignupPage';

interface ISignupPageControlsProps {
    errors: FieldErrors<IProfileCreateForm>;
    disabled: boolean;
    register: UseFormRegister<IProfileCreateForm>;
}

export const SignupPageControls = ({disabled, register, errors}: ISignupPageControlsProps) => {
    return (
        <Container className="container_for_sign_up">
            <h2 className="SignupPage__form-heading">Are you looking to</h2>
            <Row>
                <Col xs={12} lg={6}>
                    <div className="text-left SignupPage__profession-group">
                        <Form.Check
                            {...register('role')}
                            id="SignupPageControls.Role.client"
                            value={Role.Client}
                            inline
                            className="sign_up_radio_button"
                            type="radio"
                            label="Utilise Services through the platform"
                            disabled={disabled}
                        />
                        {errors && errors?.role && (
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.role.message ? 'You must select one option.' : ''}
                            </Form.Control.Feedback>
                        )}
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="text-left SignupPage__profession-group">
                        <Form.Check
                            {...register('role')}
                            id="SignupPageControls.Role.provider"
                            value={Role.Provider}
                            inline
                            className="sign_up_radio_button"
                            type="radio"
                            label="Provide services through the platform"
                            disabled={disabled}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Form.Group className="AuthPage__form-group" controlId="firstname">
                        <Form.Control
                            type="text"
                            {...register('firstname')}
                            placeholder="First Name"
                            className="AuthPage__input m-0"
                            disabled={disabled}
                            isInvalid={!!errors.firstname}
                            autoComplete="given-name"
                        />
                        {errors && errors?.firstname && (
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.firstname.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className="AuthPage__form-group" controlId="lastname">
                        <Form.Control
                            type="text"
                            {...register('lastname')}
                            placeholder="Last Name"
                            disabled={disabled}
                            className="AuthPage__input m-0"
                            isInvalid={!!errors.lastname}
                            autoComplete="family-name"
                        />
                        {errors && errors?.lastname && (
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.lastname.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Form.Group className="AuthPage__form-group" controlId="email">
                        <Form.Control
                            type="text"
                            {...register('email')}
                            className="AuthPage__input m-0"
                            placeholder="Email Address"
                            disabled
                            isInvalid={!!errors.email}
                            autoComplete="email"
                        />
                        <Form.Text className="text-muted">
                            The email address you used to sign in.
                        </Form.Text>
                        {errors && errors?.email && (
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.email.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className="AuthPage__form-group" controlId="phone_number">
                        <Form.Control
                            type="text"
                            {...register('phone_number')}
                            className="AuthPage__input m-0"
                            placeholder="Phone number"
                            disabled={disabled}
                            isInvalid={!!errors.phone_number}
                            autoComplete="tel"
                        />
                        {errors && errors?.phone_number && (
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.phone_number.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="AuthPage__form-group" controlId="is_accepted_terms">
                <Form.Check custom className="SignupPage__checkbox">
                    <Form.Check.Input
                        type="checkbox"
                        {...register('is_accepted_terms')}
                        value="true"
                        isInvalid={!!errors.is_accepted_terms}
                        disabled={disabled}
                    />
                    <Form.Check.Label>
                        I agree to the <Link
                        to="/terms"
                        target="_blank"
                        className="SignupPage__terms-link"
                    >Terms and Conditions</Link> and <Link
                        to="/privacy"
                        target="_blank"
                        className="SignupPage__terms-link"
                    >Privacy Policy</Link>
                    </Form.Check.Label>
                </Form.Check>
                {errors && errors?.is_accepted_terms && (
                    <Form.Control.Feedback type="invalid" style={{marginLeft: '-1rem'}} className="d-block">
                        You must agree to the terms and conditions and privacy policy
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <p className="SignupPage__footer-note">
                * If you are looking to do both, select which activity you will do the most.
            </p>
        </Container>
    );
};
