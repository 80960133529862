export interface IAuthPermissions {
    system: boolean;
    client: boolean;
    provider: boolean;
}

export enum Scope {
    Service = 'SERVICE',
    System = 'SYSTEM_EDIT',
    SelfEdit = 'SELF_EDIT',
    RoleClient = 'ROLE_CLIENT',
    RoleProvider = 'ROLE_PROVIDER',
}

export interface ITokenData {
    id: string;
    scopes: Scope[];
    permissions: IAuthPermissions;
    email?: string;
    emailVerified?: boolean;
}

export interface ILogoutOptions {
    returnTo?: string;
}

export interface ILoginOptions {
    from?: string;
    screen_hint?: 'signup' | 'login' | string;
}

export const authRedirectLocalStorageKey = 'authRedirect';