import React from 'react';

import {Button, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {PostKind} from 'modules/post/models/postKind';
import {IPost} from 'modules/post/models/IPost';
import {postKindFilters} from 'modules/post/constants/post-kind';
import {useProfilePostsLoadable} from 'modules/post/hooks/useProfilePostsLoadable';

import {PostCarousel} from 'shared/components/PostCarousel/PostCarousel';
import {CarouselNav} from 'public/HomePage/shared/CarouselNav/CarouselNav';
import {BadgeFilter} from 'shared/components/BadgeFilter/BadgeFilter';
import {ProfilePageEmpty} from 'shared/components/ProfilePageContent/shared/ProfilePageEmpty/ProfilePageEmpty';
import {
    ProfilePageSectionHeader,
} from 'shared/components/ProfilePageContent/shared/ProfilePageSectionHeader/ProfilePageSectionHeader';

import './style.scss';

interface IProfilePageResourcesProps {
    userId: string;
    firstname: string;
    isEditable?: boolean;
}

export const ProfilePageResources = ({userId, firstname, isEditable}: IProfilePageResourcesProps) => {
    const [postKind, setPostKind] = React.useState<PostKind | undefined>();

    const {postList, loading, error} = useProfilePostsLoadable(userId);

    const allPosts: IPost[] = postList?.posts ?? [];

    // filter the list of available PostKind filters so they only include posts that the user has
    const availablePostKindFilters = postKindFilters.filter(
        (postKindFilter) => !postKindFilter.value || !!allPosts.find((post) => post.kind === postKindFilter.value),
    );

    return (
        <>
            <div className="ProfilePageResources">
                <Container className="p-0">
                    <ProfilePageSectionHeader
                        title={`${firstname}'s Resources`}
                        description={`See what ${firstname} has been up to`}
                    >
                        {isEditable && (
                            <Button as={Link} variant="outline-primary" to="/dashboard/resources/create">
                                Add Resources
                            </Button>
                        )}
                    </ProfilePageSectionHeader>
                </Container>
                {(loading || error || !!allPosts?.length) && (
                    <>
                        <CarouselNav className="">
                            <div className="badgeFiltermobile ">
                                {availablePostKindFilters.map((postKindFilter) => (
                                    <BadgeFilter
                                        key={postKindFilter.value || ''}
                                        onClick={() => setPostKind(postKindFilter.value)}
                                        title={postKindFilter.title}
                                        active={postKind === postKindFilter.value}
                                    />
                                ))}
                            </div>
                        </CarouselNav>
                        <PostCarousel
                            kind={postKind}
                            firstname={firstname}
                            posts={allPosts}
                            loading={loading}
                            error={error ? 'Error loading posts' : undefined}
                        />
                    </>
                )}
                {!loading && !error && !allPosts?.length && (
                    <ProfilePageEmpty>
                        <div className="ProfilePageAbout--empty justify-content-center align-content-center">
                            <p className="NoData mb-0">
                                {firstname ? `${firstname} has not yet uploaded any posts` : 'There are no resources.'}
                            </p>
                            <img src="/assets/profileIcons/resource.svg" className="AboutmeIMG" alt="" />
                        </div>
                        {/* {firstname ? `${firstname} has not yet uploaded any posts` : 'There are no resources.'} */}
                    </ProfilePageEmpty>
                )}
            </div>
        </>
    );
};
