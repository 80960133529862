import React from 'react';

import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {useNavbar} from 'shared/hooks/useNavbar';
import useLoadingPage from 'shared/hooks/useLoadingPage';
import {useHomepageCache} from 'modules/homepage/hooks';
import {tagListAtom} from 'modules/tag/state/tag-list';
import {useBool} from 'shared/hooks/useBool';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {useWindowSize} from 'shared/hooks/useWindowSize';

import {HomePageHeader} from 'public/HomePage/HomePageHeader/HomePageHeader';
import {HomePageFeatures} from 'public/HomePage/HomePageFeatures/HomePageFeatures';
import {HomePageProfiles} from 'public/HomePage/HomePageProfiles/HomePageProfiles';
import {HomePagePosts} from 'public/HomePage/HomePagePosts/HomePagePosts';
import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {HomeAboutpage} from 'public/HomePage/HomeAboutpage/HomeAboutpage';
import {HomeWorkPage} from './HomeWorkPage/HomeWorkPage';
import {HomepageSlider} from './HomepageSlider/HomepageSlider';
import {HomePageReview} from './HomePageReview/HomePageReview';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {HomePageJobs} from 'public/HomePage/HomePageJobs/HomePageJobs';
import {InteractiveLogo} from 'shared/components/InteractiveLogo/InteractiveLogo';

import './style.scss';

export const HomePage = () => {
    useNavbar(true);

    const {isAuthenticated, login} = useAuth();
    const {width} = useWindowSize();

    const [isHeaderLoaded, setIsHeaderLoaded] = useBool();

    const {homepageCache, loading} = useHomepageCache();
    useLoadingPage(loading && !isHeaderLoaded);

    const setTagList = useSetRecoilState(tagListAtom);
    React.useEffect(() => {
        if (homepageCache) {
            setTagList(homepageCache.tags);
        }
    }, [homepageCache, setTagList]);

    const onLoginClick = (e: React.MouseEvent) => {
        e.preventDefault();
        login();
    };

    const onSignupClick = (e: React.MouseEvent) => {
        e.preventDefault();
        login({
            screen_hint: 'signup',
        });
    };

    return (
        <div className="HomePage">
            <PageHelmet
                title="Rightful"
                noTemplate
                description="Where legal projects come together. Easily source legal talent, build a legal project, manage payments and so much more."
            />

            {width < 769 ? (
                <>
                    <div className="HomePage__section HomePageHeader">
                        <div className="InteractiveLogo">
                            <img src="/assets/logo-mobile.gif" className="img-fluid" alt="Rightful logo" />
                        </div>
                        <h2 style={{marginBottom: '18px'}} className="HomePageHeader__title">
                            Where legal projects
                            <br />
                            come together
                        </h2>
                        <div className="d-flex justify-content-center">
                            <p className="HomePageHeader__textdes">
                                Easily source legal talent, build a legal project, manage payments and so much more
                                <br />
                            </p>
                        </div>
                        <div className="homepage-auth--button">
                            {isAuthenticated ? (
                                <Button
                                    style={{
                                        paddingInline: '35px',
                                    }}
                                    as={Link}
                                    variant="featured"
                                    className="AppNavbar__button"
                                    to="/dashboard/"
                                >
                                    Go To Dashboard
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        style={{
                                            paddingInline: '35px',
                                        }}
                                        as={Link}
                                        variant="featured-inverted"
                                        className="AppNavbar__button focusNone"
                                        to="/login"
                                        onClick={onLoginClick}
                                    >
                                        SIGN IN
                                    </Button>
                                    <Button
                                        style={{
                                            paddingInline: '35px',
                                        }}
                                        as={Link}
                                        variant="featured"
                                        className="AppNavbar__button"
                                        to="/signup"
                                        onClick={onSignupClick}
                                    >
                                        SIGN UP
                                    </Button>
                                </>
                            )}
                        </div>
                        <div className="hompage-network-button">
                            <Button
                                style={{
                                    paddingInline: '35px',
                                }}
                                as={Link}
                                variant="featured-inverted"
                                className="AppNavbar__button focusNone"
                                to="/network"
                            >
                                Network
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <HomePageHeader onLoad={() => setIsHeaderLoaded(true)} />
                    <HomeAboutpage />
                    <HomePageFeatures />
                    <HomeWorkPage />
                    <HomepageSlider />
                    <HomePageProfiles />
                    <HomePageReview />
                    <HomePagePosts />
                    <HomePageJobs />
                    <AppFooter subscribe={true} />
                </>
            )}
        </div>
    );
};
