import React, {useEffect, useState} from 'react';

import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import {useRecoilValue} from 'recoil';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';

import {SignupStatus} from 'modules/profile/models/signup-status';
import {navbarState} from 'shared/state/navbar';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';

import {NavbarNotifications} from 'shared/components/AppNavbar/NavbarNotifications/NavbarNotifications';
import {AppNavbarDropdown} from 'shared/components/AppNavbar/AppNavbarDropdown';

import './style.scss';

interface IAppNavbarProps {
    children?: React.ReactNode;
}

export const AppNavbar = ({children}: IAppNavbarProps) => {
    const {isAuthenticated, tokenData, signOut, login} = useAuth();
    const location = useLocation();
    const {profile, signupStatus} = useSignupStatus();

    // We use two variables to manage the slide-in and slide-out animation.
    // If we only use isToggled, there is a slide out animation when the page first loads. To fix it, we only add the
    // animation classes if isActivated is true,
    const [isActivated, setIsActivated] = useState<boolean>(false);
    const [isToggled, setIsToggled] = useState<boolean>(false);

    // Collapse the sidebar when navigating to another page
    useEffect(() => {
        setIsActivated(false);
        setIsToggled(false);
    }, [location]);

    // Do not show anything if the navbar is disabled for this page, via the `navbarState` recoil state
    const showNavbar = useRecoilValue(navbarState);
    if (!showNavbar) {
        return null;
    }

    const onToggleClick = (e: React.MouseEvent) => {
        e.preventDefault();

        setIsActivated(true);
        setIsToggled(!isToggled);
    };

    const onLoginClick = (e: React.MouseEvent) => {
        e.preventDefault();
        login();
    };

    const onSignupClick = (e: React.MouseEvent) => {
        e.preventDefault();
        login({
            screen_hint: 'signup',
        });
    };

    return (
        <header className="AppNavbar">
            <div
                className={classNames('AppNavbar__backdrop', {
                    'slide-out': isActivated && !isToggled,
                    'slide-in': isActivated && isToggled,
                })}
            >
                <aside className={isToggled ? 'AppNavbar__aside--open' : 'AppNavbar__aside--close'}>
                    <div className="AppNavbar__aside-close">
                        <img
                            style={{
                                width: '145px',
                            }}
                            className="AppNavbar__brand"
                            src="/assets/rightful-logo.png"
                            alt="Rightful Logo"
                        />
                        <div onClick={onToggleClick} className="CloseIcon">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="AppMobileMenu">
                        <div className="AppMobileMenu__content">
                            <Link
                                className={classNames('AppNavbar__nav-item--link AppNavbar__link Mobile', {
                                    'AppNavbar__link--active': location.pathname === '/',
                                })}
                                to="/"
                            >
                                Home
                            </Link>
                            {children}
                        </div>
                        <div className="AppMobileMenu__overlay" onClick={onToggleClick}></div>
                    </div>
                </aside>
            </div>
            <div className="container-fluid d-flex justify-content-between">
                <div className="col AppNavbar__col AppNavbar__col--nav-items AppNavbar__col--toggle">
                    <Link className="AppNavbar__link" to="#" onClick={onToggleClick}>
                        <FontAwesomeIcon icon={faBars} />
                    </Link>
                </div>
                <div className="col col-md-5 AppNavbar__col AppNavbar__col--nav-items AppNavbar__col--nav-items-collapse">
                    {children}
                </div>
                <div className="col col-md-2 AppNavbar__col AppNavbar__col--brand">
                    <Link to="/" className="d-inline-block position-relative">
                        <img className="AppNavbar__brand" src="/assets/RightfulLogo.png" alt="Rightful Logo" />
                    </Link>
                </div>
                <div className="col-sm-7 col-md-5 AppNavbar__col AppNavbar__col--nav-items AppNavbar__col--nav-items-right">
                    {isAuthenticated && signupStatus === SignupStatus.NotSignedUp && (
                        <div className="AppNavbar__nav-item small_padding d-none d-md-block">
                            <Button
                                as={Link}
                                variant="featured"
                                className="AppNavbar__button AppNavbar__button--fixed-width-lg"
                                to="/"
                            >
                                FINISH SETUP
                            </Button>
                        </div>
                    )}
                    {isAuthenticated && tokenData && profile && (
                        <>
                            <NavbarNotifications />
                            <AppNavbarDropdown tokenData={tokenData} signOut={signOut} profile={profile} />
                        </>
                    )}
                    {!isAuthenticated && (
                        <>
                            <div className="AppNavbar__nav-item small_padding focusNone d-none d-md-block">
                                <Button
                                    as={Link}
                                    variant="featured-inverted"
                                    className="AppNavbar__button AppNavbar__button--fixed-width focusNone"
                                    to="/login"
                                    onClick={onLoginClick}
                                >
                                    SIGN IN
                                </Button>
                            </div>
                            <div className="AppNavbar__nav-item small_padding d-none d-md-block">
                                <Button
                                    as={Link}
                                    variant="featured"
                                    className="AppNavbar__button AppNavbar__button--fixed-width"
                                    to="/signup"
                                    onClick={onSignupClick}
                                >
                                    Join for free
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};
