import React from 'react';

import {useAuth} from 'shared/auth/hooks/useAuth';

import {Loading} from 'shared/components/loading/Loading/Loading';

export const LogoutPage = () => {
    const {signOut} = useAuth();

    React.useEffect(() => {
        signOut();
    });

    return (
        <Loading/>
    );
};
