import React from 'react';

import Gleap from 'gleap';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';

import {Config} from 'config';
import reportWebVitals from './reportWebVitals';

import {App} from 'App';
import {ToastContainer} from 'shared/components/ToastContainer/ToastContainer';
import {LoadingPage} from 'shared/components/loading/LoadingPage/LoadingPage';
import {AuthProvider} from 'shared/auth/components/AuthProvider/AuthProvider';
import {ChatWebSocketProvider} from 'modules/chat-websocket/context/ChatWebsocketProvider';

import './styles/_main.scss';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <AuthProvider>
                    <ChatWebSocketProvider>
                        <App />
                    </ChatWebSocketProvider>
                    <LoadingPage />
                </AuthProvider>
            </BrowserRouter>
        </RecoilRoot>
        <ToastContainer />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Please make sure to call this method only once!
Gleap.initialize(Config.gleapApiKey);
