import {
    endOfDay,
    format,
    formatDistanceStrict,
    formatDistanceToNowStrict,
    formatRelative,
    isSameDay,
    parseISO,
    startOfDay,
    setDay,
} from 'date-fns';
import {format as formatTZ, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';

interface IParseAndFormatDistanceProps {
    date: string | Date;
    addSuffix?: boolean;
}

interface IParseDateProps {
    date: string | Date;
    utc?: boolean;
}

export const shortDateFormat = 'dd/MM/yyyy';
export const readableShortDateFormat = 'do MMM yyyy';
export const readableLongDateFormat = 'do MMMM, yyyy';
export const longDateFormat = 'd MMMM yyyy';
export const timeFormat = 'h:mm aa';
export const filenameTimestampFormat = 'yyyy-MM-dd HH:mm';
export const isoDateTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSxxx';

/**
 * Parse a date that might also be an ISO UTC string.
 * @param date
 */
export const safelyParseDate = (date: Date | string): Date => {
    return typeof date === 'string' ? parseISO(date) : date;
};

export const formatFromNow = (date: Date | string) => {
    return formatDistanceStrict(safelyParseDate(date), new Date(), {addSuffix: true});
};

export const formatDateShort = (date: Date | string) => {
    return formatDate(date, shortDateFormat);
};

export const formatDateOnlyReadableShort = (date: Date | string) => {
    return formatDate(date, readableShortDateFormat);
};

export const formatDateOnlyReadableLong = (date: Date | string) => {
    return formatDate(date, readableLongDateFormat);
};

export const formatDate = (date: Date | string, formatString: string) => {
    return format(safelyParseDate(date), formatString);
};

export const formatTimeRelative = (date: Date | string) => {
    const dateAsDate = safelyParseDate(date);
    const now = new Date();
    if (isSameDay(dateAsDate, now)) {
        return format(dateAsDate, timeFormat);
    } else {
        return formatRelative(dateAsDate, now);
    }
};

export const parseDate = ({date, utc}: IParseDateProps): Date => {
    if (typeof date === 'string') {
        let parsedDate = new Date(date);
        if (utc) {
            parsedDate = zonedTimeToUtc(parsedDate, 'utc');
        }
        return parsedDate;
    } else {
        return date;
    }
};

export const formatFilenameTimestamp = (date: Date | string) => {
    const dateAsDate = safelyParseDate(date);
    return format(dateAsDate, filenameTimestampFormat);
};

export const formatHoursAndMinutesAsTime = (hours: number, minutes: number) => {
    const date = new Date(1999, 1, 1, hours, minutes);
    return format(date, timeFormat);
};

export const formatStartOfTodayUTC = () => {
    return formatISOUTC(startOfDay(new Date()));
};

export const formatEndOfTodayUTC = () => {
    return formatISOUTC(endOfDay(new Date()));
};

export const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const dateToUTC = (date: Date): Date => {
    return utcToZonedTime(date, 'UTC');
};

export const formatISOUTC = (date: Date): string => {
    return formatTZ(dateToUTC(date), isoDateTimeFormat, {timeZone: 'UTC'});
};

export const parseAndFormatDistance = ({date, addSuffix}: IParseAndFormatDistanceProps) => {
    const parsedDate = safelyParseDate(date);
    return formatDistanceToNowStrict(parsedDate, {
        addSuffix: addSuffix ?? false,
    });
};

export const formatDayOfWeek = (dayOfWeek: number, short?: boolean) => {
    return format(setDay(new Date(), dayOfWeek), short ? 'E' : 'EEEE');
};

export const convertDateFormat = (inputDateString: string) => {
    const parsedDate = parseISO(inputDateString);
    return format(parsedDate, 'dd/MM/yyyy h:mm a');
};

export const convertTimeLocalSystem = (date: Date | string) => {
    const dateAsDate = safelyParseDate(date);
    const pcTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const pcLocalTime = utcToZonedTime(dateAsDate, pcTimezone);
    const now = new Date();
    if (isSameDay(dateAsDate, now)) {
        return format(pcLocalTime, timeFormat);
    } else {
        return formatRelative(pcLocalTime, now);
    }
};
