import React from 'react';

import './style.scss';

interface IPageErrorProps {
    children?: React.ReactNode;
    description?: string;
}

export const PageError = ({children, description}: IPageErrorProps) => (
    <div className="PageError">
        <img
            src="/assets/notfound.png"
            alt={description}
            className="PageError__image"
        />
        <div>
            {children ?? description}
        </div>
    </div>
);
