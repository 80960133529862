import React, {useState} from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {useRecoilCallback, useRecoilValue} from 'recoil';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {toast} from 'react-toastify';

import {IFilterOption} from 'shared/models/filter-option/IFilterOption';
import {IProfilesPageFilters, marketplacePageProfiles} from 'shared/state/profiles-page';
import {IProfile} from 'modules/profile/models/IProfile';
import {capitalizeFirstLetterSimple} from 'shared/utils/string';
import {useNavbar} from 'shared/hooks/useNavbar';
import useLoadingPage from 'shared/hooks/useLoadingPage';
import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';
import {marketplaceInfiniteScrollAtom} from 'modules/profile/state/marketplace-infinite-scroll';
import {readProfileList} from 'modules/profile/api/profile';
import {getErrorMessage} from 'shared/utils/error';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {ProfileCard} from 'shared/components/ProfileCard/ProfileCard';
import {ProfilesPageSearch} from 'public/ProfileMarketplace/ProfilesPageSearch/ProfilesPageSearch';
import {MarketplaceFilter} from 'public/ProfileMarketplace/MarketplaceFilter/MarketplaceFilter';
import {PageError} from 'shared/components/PageError/PageError';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {BookingModal} from 'shared/components/BookingModal/BookingModal';
import ProfileCardSkeleton from './ProfileCardSkeleton';

import 'animate.css';
import './style.scss';

export const ProfileMarketplace = () => {
    const {profile: currentProfile} = useSignupStatus();

    const profileListAtom = useRecoilValue(marketplaceInfiniteScrollAtom);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [isNextLoading, setIsNextLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState<IProfilesPageFilters>({
        page: 0,
        limit: 30,
    });

    const [showSkeleton, setShowSkeletons] = React.useState<boolean>(false);
    const [isOnlySkeleton, setIsOnlySkeleton] = React.useState<boolean>(false);

    const [bookingModalProfile, setBookingModalProfile] = React.useState<IProfile | undefined>(undefined);
    const [isAllProfileLoaded, setIsAllProfileLoaded] = React.useState<boolean>(false);
    // Show the loading page during initial page load
    // NOTE: this should come after the state hook so that we can set the default value to false if already loaded
    const isInitialLoad = !profileListAtom;
    useNavbar(!isInitialLoad);
    useLoadingPage(isInitialLoad);

    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [locationOptions, setLocationOptions] = React.useState<IFilterOption<string | undefined>[]>([]);

    const filteredProfiles = useRecoilValue(marketplacePageProfiles(filters));

    const loadMoreProfiles = useRecoilCallback(({snapshot, set}) => async (isLoadMore: boolean) => {
        setLoading(true);
        const atomValue = await snapshot.getPromise(marketplaceInfiniteScrollAtom);
        const newValue = {...atomValue};

        // SAFETY: when you return to the page it should retain the previous state and not load again
        if (
            !isLoadMore &&
            !!Object.values(newValue).length &&
            JSON.stringify(newValue.filters) === JSON.stringify(filters)
        ) {
            setLoading(false);
            return;
        }

        // SAFETY: if at the end of the list it should not load more
        if (profileListAtom && !newValue.nextCursor && JSON.stringify(newValue.filters) === JSON.stringify(filters)) {
            setLoading(false);
            return;
        }

        if (filters.searchTerm !== atomValue?.filters.searchTerm) {
            if (newValue) {
                newValue.profiles = [];
                newValue.nextCursor = undefined;
                newValue.currentCursor = undefined;
            }
        }

        try {
            // TODO: check not at end of list
            const profileList = await readProfileList({
                isPublished: true,
                limit: filters.limit,
                cursor: newValue?.nextCursor,
                searchTerm: filters.searchTerm,
            });
            setIsOnlySkeleton(false);
            setShowSkeletons(false);
            if (isLoadMore) {
                set(marketplaceInfiniteScrollAtom, {
                    profiles: [...(newValue?.profiles ?? []), ...profileList.profiles],
                    nextCursor: profileList.nextCursor,
                    currentCursor: newValue?.nextCursor,
                    filters: filters,
                });
            } else {
                set(marketplaceInfiniteScrollAtom, {
                    profiles: profileList.profiles,
                    nextCursor: profileList.nextCursor,
                    currentCursor: newValue?.nextCursor,
                    filters: filters,
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            toast.error('Sign-Up Error', getErrorMessage(e));
            setError(!!e);
        } finally {
            setLoading(false);
        }
    });

    const [sentryBottomRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: isNextLoading ? !!profileListAtom?.nextCursor : false,
        onLoadMore: () => loadMoreProfiles(true),
        disabled: error,
        rootMargin: '0px 750px 750px 0px',
    });

    const [sentryRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: isNextLoading ? !!profileListAtom?.nextCursor : false,
        onLoadMore: () => loadMoreProfiles(true),
        disabled: error,
        rootMargin: '0px 0px 750px 0px',
    });

    React.useEffect(() => {
        if (profileListAtom && !locationOptions.length) {
            const cityValues: (string | undefined)[] = profileListAtom.profiles.map((profile) => {
                return profile?.contact_details_display?.city ? profile.contact_details_display.city.trim() : undefined;
            });
            const uniqueCities = [...new Set(cityValues.filter((city) => !!city))] as string[];
            setLocationOptions([
                {
                    title: 'All',
                    value: undefined,
                },
                ...uniqueCities.map((city) => ({
                    title: capitalizeFirstLetterSimple(city),
                    value: city,
                })),
            ]);
        }
    }, [profileListAtom, locationOptions, setLocationOptions]);

    React.useEffect(() => {
        // if all profile loaded then prevent api call
        if (!isAllProfileLoaded) {
            loadMoreProfiles(false);
            setIsAllProfileLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    // We want to keep the skeletons around till the next frame
    React.useEffect(() => {
        if (isNextLoading) {
            if (!loading && profileListAtom && !profileListAtom.nextCursor) {
                setShowSkeletons(false);
            } else {
                setShowSkeletons(true);
            }
        } else {
            if (loading) {
                setShowSkeletons(true);
            } else {
                setShowSkeletons(false);
            }
        }
    }, [profileListAtom?.profiles, loading, setShowSkeletons, profileListAtom, isNextLoading]);

    // Before we've loaded return null so that there isn't a visible flash while the loading page pops up.
    if (isInitialLoad) {
        return null;
    }

    return (
        <>
            <PageHelmet
                title="Network"
                description="Find the best legal service providers in Australia in the Rightful network."
            />
            <Container className="ProfilesPage">
                <ProfilesPageSearch
                    showModal={() => setShowFilter(true)}
                    listFilters={filters}
                    setFilters={(filters) => setFilters(filters)}
                />
                <MarketplaceFilter
                    showFilter={showFilter}
                    locationOptions={locationOptions}
                    filters={filters}
                    onChange={setFilters}
                    setShowFilter={setShowFilter}
                    setIsNextLoading={setIsNextLoading}
                    setShowSkeletons={setShowSkeletons}
                    setIsOnlySkeleton={setIsOnlySkeleton}
                />
                {!isOnlySkeleton && profileListAtom && !!filteredProfiles.length && (
                    <Row className="gx-5 align-items-center">
                        {filteredProfiles.map((profile, index, arr) => (
                            <Col
                                key={profile.id}
                                sm={12}
                                md={6}
                                xl={4}
                                className={'mobilePostView mb-3 animate__animated animate__fadeIn'}
                                style={{zIndex: arr.length - (index + 1)}}
                            >
                                <ProfileCard
                                    currentRole={currentProfile?.role}
                                    profile={profile}
                                    className="ProfileCard"
                                    onAvailabilityClick={() => setBookingModalProfile(profile)}
                                    isFromNetwork={true}
                                />
                                {Math.floor(filteredProfiles.length / 2) - 10 === index && (
                                    <div className="loadRef" ref={sentryRef} />
                                )}
                            </Col>
                        ))}
                    </Row>
                )}
                {showSkeleton && (
                    <Row className="gx-5 align-items-center">
                        {[...Array(3)].map((_, index) => (
                            <Col
                                key={index}
                                sm={12}
                                md={6}
                                xl={4}
                                className={'mobilePostView mb-3 animate__animated animate__fadeIn '}
                            >
                                <ProfileCardSkeleton />
                            </Col>
                        ))}
                    </Row>
                )}

                {profileListAtom && !filteredProfiles.length && (
                    <div className="text-muted lead text-center my-5 py-5 px-2">
                        {!!filters.searchTerm?.length
                            ? 'There are no profiles matching that search.'
                            : 'There are no profiles at the moment.'}
                    </div>
                )}
                {error && <PageError description="Error loading profiles" />}
            </Container>
            <BookingModal
                profile={bookingModalProfile}
                show={!!bookingModalProfile}
                onHide={() => setBookingModalProfile(undefined)}
            />
            {/* <div ref={sentryBottomRef} /> */}
            <div className="loadRef" ref={sentryBottomRef} />
            <AppFooter />
        </>
    );
};
